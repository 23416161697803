import React, { useState } from 'react';

import { useRouter } from 'next/router';

import { StackProps } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';

import { InOutIcon } from '@/components/shared/InOutIcon/InOutIcon';
import { SamplesDetailsPropertyValue } from './SamplesDetailsPropertyValue/SamplesDetailsPropertyValue';

import { StyledText, StyledToolTip } from '@/components/lib';
import {
  StyledContainer,
  StyledReasonText,
  StyledRowContainer,
  StyledTitleContainer
} from './SampleDetailsDialogBodyCard.styles';

import { routes } from '@/helpers/routes';
import { propsMarkingColor } from '@/helpers/utils/getMarkedText';
import { UpdatePropertySchema } from '@/helpers/utils/deepCloneUpdates';
import { PerPropertySchema, PipelineElementType, PropertyDefinitionSchema, PropertyType } from '@/helpers/services/api';

import { constants as samplesConstants } from '@/components/Samples/SamplesTable/samplesTable.constants';

interface SamplesTableDialogBodyCardProps extends StackProps {
  reason?: string;
  kind: PropertyType;
  staticItem?: boolean;
  propertyToMark?: string;
  userInteractionId?: string;
  switchToTranslated?: boolean;
  ignoreReasoningTooltip?: boolean;
  allMarkingDetails?: PerPropertySchema[];
  propertyDefinition?: PropertyDefinitionSchema;
  card: { title: string; value: number | string };
  handleDelete?: () => void;
  setSection?: (section: string) => void;
  setPropertyToMark?: (property: string) => void;
  setIsTranslation?: (isTranslation: boolean) => void;
  updateProperty?: (updateData: UpdatePropertySchema) => void;
}

const constants = {
  moreDetails: 'More details',
  markingExplanation: (propName?: string) =>
    propName === 'Grounded in Context'
      ? '* The highlighted  sentences on the output received the lowest score'
      : '* The most relevant paragraph is highlighted in the document',
  tooltipText: (description?: string, reason?: string, value?: string) =>
    `${description ?? ''}${reason ? `\n\n Score Reasoning - \n\n ${reason}` : ''}${value ? `\nValues: ${value}` : ''}`
};

const { sampleKey, informationRetrievalKey } = samplesConstants;

export const SampleDetailsDialogBodyCard = (props: SamplesTableDialogBodyCardProps) => {
  const {
    card,
    kind,
    reason,
    propertyToMark,
    userInteractionId,
    allMarkingDetails,
    propertyDefinition,
    switchToTranslated,
    ignoreReasoningTooltip,
    setSection,
    handleDelete,
    updateProperty,
    setIsTranslation,
    setPropertyToMark,
    ...otherProps
  } = props;

  const [isReasonView, setIsReasonView] = useState(false);
  const [isInputFieldOpen, setIsInputFieldOpen] = useState(false);
  const [popupAnchor, setPopupAnchor] = useState<HTMLElement | null>(null);

  const { push } = useRouter();

  const title = card?.title;
  const isExpandLogic = reason && ignoreReasoningTooltip;
  const isMarkingAvailable = Array?.isArray(allMarkingDetails)
    ? allMarkingDetails?.map(property => property?.name)?.includes(title)
    : false && setPropertyToMark;
  const isClickable = !!isExpandLogic || !!isMarkingAvailable || kind === PropertyType['custom'];

  const propertyToMarkDetails = Array?.isArray(allMarkingDetails)
    ? (allMarkingDetails?.find(property => property?.name === title) as PerPropertySchema & string)
    : undefined;

  const handleRedirect = () => {
    push(
      `${routes.config.properties}?drillDownPropertyName=${propertyDefinition?.property_name}&drillDownPropertyType=${kind}`
    );
  };

  const handleContainerClick = (e?: HTMLElement) => {
    if (isExpandLogic) {
      setIsReasonView(!isReasonView);
    } else if (isMarkingAvailable) {
      if (propertyToMark !== title) {
        setPropertyToMark && setPropertyToMark(title as string);
        switchToTranslated && setIsTranslation && setIsTranslation(true);

        const sectionToMark = () => {
          switch (propertyToMarkDetails?.element_to_mark) {
            case PipelineElementType['information_retrieval']:
              return informationRetrievalKey;
            default:
              return typeof propertyToMarkDetails?.metadata?.[0]?.document_index === 'number'
                ? informationRetrievalKey // Fallback if server is wrong about index <=> element_to_mark
                : sampleKey;
          }
        };

        setSection && setSection(sectionToMark());
      } else {
        setPropertyToMark && setPropertyToMark('');
      }
    } else if (kind === PropertyType['custom']) {
      propertyDefinition?.column_type === 'numeric' ? setIsInputFieldOpen(true) : setPopupAnchor(e ?? null);
    }
  };

  return (
    <StyledContainer
      {...otherProps}
      clickable={isClickable}
      custom={kind === PropertyType['custom']}
      expand={!!isReasonView || propertyToMark === title}
      data-testid={`SampleDetailsDialogBodyCard-${title}-${kind}`}
      property={title === propertyToMark ? propertyToMarkDetails : undefined}
      onClick={e => handleContainerClick(e?.currentTarget)}
    >
      <StyledToolTip
        maxHeight="400px"
        placement="right"
        text={propertyDefinition?.property_name}
        link={{ onClickOverride: handleRedirect, label: constants.moreDetails }}
        descriptions={constants.tooltipText(
          propertyDefinition?.description,
          !ignoreReasoningTooltip ? reason : '',
          Array?.isArray(card?.value) ? card?.value?.join(', ') : '' // show value in tooltip only if it is an array
        )}
      >
        <StyledTitleContainer>
          <StyledText text={title} type="bodyBold" />
          {handleDelete && (
            <HighlightOff fontSize="small" onClick={handleDelete} data-testid={`HighlightOff-${title}-${kind}`} />
          )}
        </StyledTitleContainer>
      </StyledToolTip>
      <StyledRowContainer>
        <SamplesDetailsPropertyValue
          kind={kind}
          value={card?.value}
          popupAnchor={popupAnchor}
          definition={propertyDefinition}
          isInputFieldOpen={isInputFieldOpen}
          userInteractionId={userInteractionId}
          updateProperty={updateProperty}
          setPopupAnchor={setPopupAnchor}
          setIsInputFieldOpen={setIsInputFieldOpen}
        />
        <InOutIcon kind={kind} noTooltip />
      </StyledRowContainer>
      {isReasonView || propertyToMark === title ? (
        <StyledReasonText
          color={propsMarkingColor(propertyToMarkDetails)}
          type={propertyToMark === title ? 'tinyBold' : 'tiny'}
          text={reason || propertyToMarkDetails?.description || ''}
        />
      ) : (
        <></>
      )}
    </StyledContainer>
  );
};
